body#product {   
   &.product_list {
      .product-preview {
         width: 40px;
         height: 40px;
         line-height: 40px;
         display: inline-block;
         text-align: center;
         
         > img {
            max-height: 100%;
            max-width: 100%;
         }
      }
   }
   
   &.product_index {
      .content {
         .sublayout {
            .products {
               display: flex;
               flex-wrap: wrap;
               justify-content: space-around;
               margin-top: 50px;
               
               .product-one {
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  width: 250px;
                  margin-bottom: 30px;
                  transition: box-shadow 0.2s ease;
      
                  &:hover {
                     text-decoration: none;
                     box-shadow: 0px 0 10px 0px rgba(0,0,0,0.2);
                  }
      
                  > i {
                     position: absolute;
                     top: 0; right: 0;
                  }

                  .image-container {
                     display: flex;
                     justify-content: center;
                     align-items: center;
                     height: 120px;
                     padding: 0.5rem;

                     .img-thumbnail {
                        border: 0;
                        max-height: 100%;
                        align-self: center;
                     }
                  }

                  .card-body {
                     display: flex;
                     flex-direction: column;
                     align-items: center;
                     border-top: 1px solid #dee2e6;

                     .card-text {
                        margin: auto;
                     }

                     .btn-group {
                        margin-top: 1rem;
                     }
                  }
               }

               &[data-view-order="large-grid"] {
                  .product-one {
                     width: 30%;
                     font-size: 1.1em;

                     .image-container {
                        height: 240px;
                     }
                  }
               }

               &[data-view-order="list"] {
                  .product-one {
                     width: 100%;
                     flex-direction: row;
                     font-size: 1.3em;

                     .image-container {
                        width: 300px;
                        height: 300px;
                     }

                     .card-body {
                        border-top: 0;
                        border-left: 1px solid #dee2e6;
                        flex-direction: row;
                        justify-content: space-between;
                        padding: 2rem 4rem;

                        .btn-group {
                           display: flex;
                           flex-direction: column;
                           margin-top: 0;

                           > .btn {
                              border-radius: 0.25rem;
                              font-size: inherit;
                              padding: 0.5rem 1rem;

                              &:not(:last-child) {
                                 margin-bottom: 0.5rem;
                              }
                           }
                        }
                     }

                     > i {
                        font-size: 1.5em;
                     }
                  }
               }
            }
         }

         .form-control {
            height: 28px;
         }
      }
   }

   &.product_new {
      .content {
         .sublayout {
            .title {
               text-align: center;
            }
            
            form.steps {
               display: flex;
               justify-content: space-between;
               margin: 100px auto;

               .step {
                  display: flex;
                  flex-direction: column;
                  padding: 10px;
                  margin: 0 10px;
                  border: 1px solid transparent;
                  border-radius: 3px;

                  &-label {
                     text-align: center;
                  }

                  &-description {
                     margin: 20px 0;
                  }

                  &-body {
                     margin-top: auto;

                     button {
                        display: block;
                        margin: 0 auto;
                     }
                  }

                  &:hover {
                     border-color: $blue;
                  }
               }
               
               button[name="next-step"] {
                  display: block;
                  width: 100px;
                  margin: 50px auto 0;
                  
                  .spinner-border {
                     display: none;
                  }
                  
                  &[disabled] {
                     .spinner-border {
                        display: inline-block;
                        
                        + .text {
                           display: none;
                        }
                     }
                  }
               }

               > .btn {
                  align-self: flex-end;
               }
            }

            .modal {
               &#genConfigModal {
                  .modal-body {
                     .loading {
                        display: none;
                        text-align: center;

                        > .spinner-border {
                           color: gray;
                           font-size: 10px;
                           width: 2rem;
                           height: 2rem;
                        }
                     }

                     .gen-products {
                        margin-top: 1rem;

                        .product {
                           margin-bottom: 1rem;
                        }
                     }
                  }
               }
            }
         }
      }
   }
   
   &.product_edit, &.product_edit_gen {
      .product-header {
         display: flex;
         align-items: center;
         border-bottom: 1px solid rgba(0, 0, 0, 0.125);
         padding-bottom: 20px;
         
         > .nav {
            margin-left: 30px;
         }
         
         > .options {
            margin-left: auto;
            
            > form {
               display: inline-block;
            }
         }
         
         + #productParams {
            form {
               border-top: 0;
               border-top-right-radius: 0;
               border-top-left-radius: 0;

               .param {
                  display: flex;
                  align-items: baseline;
               }
            }
         }
      }

      .product-overrides-container {
         .product-overrides {
            .override {
               display: flex;

               .form-group {
                  display: flex;
                  flex-direction: column;
               }

               button.remove {
                  padding: 0;
                  color: $red;
               }
            }
         }
      }

      .pallet_form_layout {
         margin-top: 30px;
      }
   }

   &.product_edit_pictures {
      .content {
         .sublayout {
            .views {
               .view {
                  margin-top: 50px;
                  
                  .pictures {
                     display: flex;
                     flex-wrap: wrap;
                     justify-content: space-between;
                     
                     .picture {
                        width: 45%;
                        
                        &:nth-of-type(n + 3) {
                           margin-top: 50px;
                        }
                        
                        .card-body {
                           display: flex;
                           justify-content: space-around;
                           align-items: center;
                           text-align: center;
                           
                           img {
                              max-width: 250px;
                              border: 1px solid $lightgrey;
                           }
                           
                           .overlay-figure {
                              form {
                                 .overlay-actions {
                                    flex-direction: column;
                                    
                                    label, button {
                                       width: 150px;
                                    }
                                 }
                              }
                           }
                        }
                     }
                  }
               }
            }
         }
      }
   }
}

body#product, body#model {
   #context-menu {
      display: block;
      position: fixed;
      padding: 0;
      
      .dropdown-item {
         cursor: pointer;
         display: inline-flex;
         width: auto;
         padding: 8px;
         
         i {
            &.rotate-left {
               transform: rotate(90deg);
            }
            &.rotate-right {
               transform: rotate(270deg);
            }
         }
         
         &.dropright:hover {
            .dropdown-menu {
               display: block;
            }
         }
         
         .dropdown-toggle {
            display: flex;
            justify-content: space-between;
            align-items: center;
         }
      }
   }
   
   &.product_edit, &.model_show {
      .content {
         .sublayout {
            .title {
               border-bottom: 1px solid rgba(0, 0, 0, 0.125);
            }

            .infos {
               margin-top: 50px;
               
               .views {
                  .view {
                     > .dropdown {
                        .dropdown-menu {
                           form.delete-item {
                              display: block;
                              
                              button {
                                 text-decoration: none;
                                 width: 100%;
                                 border-radius: 0;
                                 
                                 &:hover {
                                    background: darken(red, 10%);
                                    color: white;
                                 }
                              }
                           }
                        }
                     }
                  }
               }
               
               .technos {
                  margin-top: 50px;
                  
                  .techno {
                     display: flex;
                     justify-content: space-between;
                     align-items: center;

                     &.new-techno {
                        align-items: stretch;
                     }
                     
                     .default-techno:not(label) {
                        border: $radio-border-width solid $radio-border-color;
                        background-color: $radio-border-color;
                        color: white;
                        padding: $radio-padding;
                        border-radius: $radio-border-radius;
                        margin: 0 20px 0 auto;
                     }
                     
                     .mk-radio {
                        margin: 0 20px 0 auto;
                        
                        .mk-radio-label {
                           opacity: 0;
                        }
                        
                        input[type="radio"]:checked {
                           + .mk-radio-label {
                              opacity: 1;
                           }
                        }
                     }
                     
                     &:hover {
                        .mk-radio {
                           input[type="radio"]:not(:checked) {
                              + .mk-radio-label {
                                 opacity: 1;
                              }
                           }
                        }
                     }

                     > .is-pseudo-checkbox {
                        label {
                           margin-bottom: 0;
                           transition: color 0.2s ease;
                        }
                        
                        input:checked {
                           + label {
                              color: $green;
                           }
                        }
                        
                        &:hover {
                           label {
                              color: $blue;
                           }
                        }
                     }
                     
                     .select2 {
                        .select2-selection {
                           border-top-right-radius: 0;
                           border-bottom-right-radius: 0;
                        }
                        
                        + :nth-of-type(1) {
                           border-top-left-radius: 0;
                           border-bottom-left-radius: 0;
                        }
                     }
                  }
               }
               
               .paramzones-container {
                  display: flex;
                  justify-content: space-between;
                  
                  > .canvas-container {
                     position: relative;
                     min-height: 600px;
                     
                     > .canvas-one {
                        position: absolute;
                        top: 0; left: 0;
                     }
                     
                     .declinaisons-container {
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        padding-right: 20px;
                        
                        .declinaison {
                           position: relative;
                           width: 96px;
                           min-height: 96px;
                           
                           img {
                              position: absolute;
                              top: 0; left: 0;
                              max-height: 96px;
                              opacity: 0.3;
                              transition: all 0.25s ease;
                           }
                           
                           &.selected {
                              img {
                                 opacity: 1;
                              }
                           }
                           
                           &:hover:not(.selected) {
                              cursor: pointer;
                              
                              img {
                                 opacity: 1;
                                 transform: scale(1.1);
                              }
                           }
                        }
                     }
                  }
                  
                  > form {
                     width: 400px;
                     
                     .actions {
                        display: flex;
                        justify-content: space-between;
                        
                        a.get-back {
                           position: unset;
                           color: initial;
                        }
                     }
                     
                     > .paramzones {
                        .view {
                           .paramzone {
                              display: none;
                              margin-top: 1rem;
                              
                              &.active {
                                 display: block;
                              }
                              
                              .zone-name {
                                 display: flex;
                                 justify-content: space-between;
                                 align-items: center;

                                 .toggle-visibility {
                                    display: flex;
                                    align-items: center;
                                    cursor: pointer;

                                    > i {
                                       color: grey;
                                       margin-left: 5px;

                                       &.visible {
                                          color: $blue;
                                       }
                                    }
                                 }
                              }
                              
                              .input-group {
                                 input[disabled] {
                                    background-color: white;
                                 }
                                 
                                 &-append, &-prepend {
                                    justify-content: center;
                                    
                                    .input-group-text {
                                       display: block;
                                       border-radius: 0;
                                    }
                                 }
                                 
                                 &-prepend {
                                    .input-group-text {
                                       width: 170px;
                                       background-color: unset;
                                    }
                                 }
                                 
                                 &-append {
                                    .input-group-text {
                                       width: 50px;
                                    }
                                 }
                                 
                                 &:not(:last-of-type) {
                                    .input-group-text, input {
                                       border-bottom: 0;
                                    }
                                 }
                              }
                              
                              .technos {
                                 > .techno {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    cursor: pointer;
                                    
                                    > span {
                                       flex: 1;
                                    }
                                    
                                    > .mk-radio {
                                       flex: 2;
                                       text-align: right;
                                    }
                                 }
                              }
                           }
                        }
                     }
                  }
               }
            }
            
            #picturesModal {
               .modal-body {
                  .loading {
                     height: 250px;
                     display: flex;
                     justify-content: center;
                     align-items: center;
                     
                     .spinner-border {
                        font-size: 10px;
                        color: gray;
                        width: 50px;
                        height: 50px;
                     }
                  }
                  
                  .pictures {
                     display: flex;
                     flex-wrap: wrap;
                     justify-content: space-around;
                     
                     .group-medias {
                        display: flex;
                        margin: 10px 0;
                        flex-wrap: wrap;
                        
                        .image-container {
                           width: 150px;
                           background-image: linear-gradient(45deg, #ccc 25%, transparent 25%, transparent 75%, #ccc 75%), linear-gradient(45deg, #ccc 25%, transparent 25%, transparent 75%, #ccc 75%);
                           background-size: 16px 16px;
                           background-position: 0 0, 8px 8px;
                           
                           &:hover {
                              cursor: pointer;
                              
                              img {
                                 border: 2px solid $blue;
                              }
                           }
                           
                           input:checked + img {
                              border: 2px solid $blue;
                           }
                           
                           img {
                              max-width: 100%;
                           }
                        }
                     }
                  }
               }
            }
         }
      }
   }
}
