
// variables
@import "variables";

// Layout
@import "general/layout";

// partials
@import "_partials/header";
@import "_partials/left-menu";
@import "_partials/overlay";

// pages
@import "pages/security";
@import "pages/index";
@import "pages/product";
@import "pages/report";
@import "pages/pallet";

// boostrap
@import "~bootstrap/scss/bootstrap";
@import "../../public/includes/vanilla-picker-master/src/index";

body {
   font-family: 'Open Sans', sans-serif;

   * {
      transition: 0.2s all ease;
   }

   input {
      &:focus {
         box-shadow: 0 0 5px 0 rgba($blue, 0.3);
      }
   }

   nav {
      .pagination {
         font-size: 1rem;

         .page-link {
            display: flex;
            height: 100%;

            i {
               font-size: 1.3em;
            }
         }
      }
   }
}

.no-item {
	font-size: 1.5rem;
	border-left: 3px solid $blue;
	margin: 2rem;
	padding: 1rem;
}

.next-step {
   display: flex;
   align-items: center;
   font-size: 1.2rem;

   > i {
      color: #ffee58;
      font-size: 1.5rem;
      -webkit-text-stroke: 1px $blue;
      margin-right: 1rem;
   }

   a {
      text-decoration: underline !important;
      cursor: pointer;
   }
}

.popin {
   z-index: 10000;

   .popin-container {
      .popin-header {
         .popin-header-title {
            color: $blue;
         }
      }

      .popin-footer {
         > :nth-child(n) {
            font-weight: normal;
            text-shadow: none;
            opacity: 1;
            border: 1px solid;
            border-color: $blue;
            border-radius: 5px;
            background-color: $blue;
            color: white;
            transition: all 0.2s ease;
            cursor: pointer;

            &[disabled] {
               opacity: 0.5;
            }

            &.close {
               background-color: grey;
               border-color: grey;

               &:not([disabled]):hover {
                  color: grey;
                  border-color: grey;
               }
            }

            &:not([disabled]):hover {
               background-color: transparent;
               color: $blue;
               border-color: $blue;
            }

            .spinner-border {
               width: 2rem;
               margin-left: 0;
               padding: 0;
            }
         }
      }
   }
}

.infos {
   .views {
      .view {
         flex-direction: row;

         &:not(:first-of-type) {
            margin-top: 40px;
         }

         > .view-image {
            padding: 30px;
            border-right: 1px solid rgba(0, 0, 0, 0.125);
            display: flex;
            align-items: center;

            img {
               max-width: 275px;
            }
         }

         > .view-content {
            flex: 1;

            .card-header {
               display: flex;
               justify-content: space-between;
               align-items: center;
               position: relative;

               .edit-field {
                  margin-left: 0;

                  input {
                     font-size: 25px;
                  }

                  &:hover {
                     background-color: white;
                  }
               }

               .entity-name {
                  font-size: 25px;
                  padding: 5px 20px;
               }

               .dropdown {
                  .dropdown-toggle::after {
                     margin-left: 0;
                  }
               }

               .view-position {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  flex-wrap: wrap;
                  position: absolute;
                  right: -50px;
                  width: 50px;
                  top: 0;
                  bottom: 0;

                  [data-order-to-go] {
                     color: darken($lightgrey, 30%);

                     &:not([disabled]) {
                        color: $blue;
                        cursor: pointer;
                     }
                  }
               }
            }

            .card-body {
               .description {
                  .edit-field {
                     margin-left: 0;
                     display: block;

                     textarea {
                        width: 100%;
                        resize: none;

                        &::placeholder {
                           font-style: italic;
                        }
                     }
                  }
               }

               .zones {
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: space-between;

                  .move-zones {
                     display: inline-flex;
                     justify-content: center;
                     align-items: center;
                     margin-left: 10px;
                  }

                  .zone {
                     width: 45%;
                     height: 45px;
                     display: flex;
                     justify-content: space-between;
                     align-items: center;
                     box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
                     margin-top: 15px;
                     border-radius: 3px;

                     .link-to-techno {
                        margin: 0;

                        input[type="checkbox"] {
                           &:checked {
                              + i.material-icons {
                                 color: $blue;
                              }
                           }

                           + i.material-icons {
                              color: grey;
                           }
                        }
                     }

                     > span.entity-name {
                        margin-left: 35px;
                     }

                     form.delete-item, .create-new {
                        border-top-right-radius: 3px;
                        border-bottom-right-radius: 3px;
                        transition: background-color 0.2s ease;
                        border-width: 1px;
                        border-style: solid;
                        height: 100%;

                        > button {
                           display: flex;
                           align-items: center;
                        }
                     }

                     form.delete-item {
                        background-color: $red;
                        border-color: $red;

                        button {
                           color: white;
                        }

                        &:hover {
                           background-color: white;

                           button {
                              color: $red;
                           }
                        }
                     }

                     &.add {
                        > span {
                           margin-left: 20px;
                           font-style: italic;
                        }

                        .create-new {
                           display: flex;
                           align-items: center;
                           background-color: $blue;
                           border-color: $blue;
                           border-top-left-radius: 0;
                           border-bottom-left-radius: 0;

                           i {
                              color: white;
                           }

                           &:hover {
                              background-color: white;

                              i {
                                 color: $blue;
                              }
                           }
                        }

                        &.dynamic-add {
                           > span {
                              display: none;
                           }

                           .dynamic-form {
                              margin: 0;

                              input:not([type="submit"]) {
                                 border: 0;
                              }
                           }
                        }
                     }

                     .edit-field {
                        height: 100%;
                        margin: 0;

                        input {
                           width: 100%;
                           height: 100%;
                        }
                     }
                  }
               }
            }
         }

         &.new-view {
            > .create-new {
               width: 100%;
            }

            form.dynamic-form {
               margin-left: 0;

               input {
                  text-align: center;
               }
            }
         }
      }
   }
}

.table {
   tbody {
      tr {
         td  {
            padding: 0.35rem 1rem;
            vertical-align: middle;

            .edit-field {
               margin: 0;

               input {
                  padding: 0;
               }
            }
         }

         &:hover {
            background-color: $lightgrey;

            td {
               .edit-field {
                  &:hover {
                     background: white;
                  }
               }
            }
         }
      }
   }
}

h1 {
   font-size: 2.2rem;
   font-weight: 700;
   
  a {
    font-size: initial;
  }
}

ul {
  list-style-type: none;

  li {
    margin-bottom: 5px;

    > span.entity-name {
      display: inline-block;
      min-width: 200px;
    }
  }
}

label {
  cursor: pointer;
}

input {
  &::placeholder {
    font-style: italic;
  }
}

.img-thumbnail {
   align-self: center;
}

form {
   &.delete-item {
      display: inline-block;

      button {
         color: $red;

         &:hover {
            color: darken($red, 25%);
         }
      }
   }

   &.dynamic-form {
      margin-left: 15px;

      input {
         border: 0;
      }
   }
}

.dynamic-add {
   form {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 100%;

      input:not([type="submit"]) {
         width: 100%;
         padding: 5px 20px;
         border: 0;
         border: 1px solid rgba(0, 0, 0, 0.125);
         border-right: 0;
         border-radius: 0.25rem;
         border-top-right-radius: 0;
         border-bottom-right-radius: 0;
      }

      [type="submit"] {
         border-top-left-radius: 0;
         border-bottom-left-radius: 0;
         display: flex;
         align-items: center;
      }

      + .create-new {
         display: none !important;
      }
   }
}

a {
  color: $blue;

  &.btn-link:hover {
    text-decoration: none !important;
  }
}

button.btn-link:hover {
  text-decoration: none;
}

a, button {
  &[class*="btn"] {
    &:active, &:focus {
      text-decoration: none !important;
      outline: 0 !important;
      box-shadow: none !important;
    }
  }
}

.edit-field {
  display: inline-block;
  position: relative;
  line-height: 1.5;
  margin-left: 15px;
  margin-right: 15px;
  flex: 1;

  &:hover {
    background-color: $lightgrey;
  }

  input, textarea {
    padding: 5px 20px;
    border: 0;
    width: 100%;
    box-shadow: 0 0 5px 0 rgba($blue, 0.3);

    &[disabled] {
      &:not(.loading) {
        border: 0;
        background: none;
        color: inherit;
        box-shadow: none;
      }

      + div {
        display: block;
        position: absolute;
        left: 0; top: 0; right: 0; bottom: 0;

        &:hover {
          cursor: text;
        }
      }
    }

    + div {
      display: none;
    }
  }
}

.alert-box {
  position: fixed;
  top: 80px;
  left: 50%;
  width: 500px;
  z-index: 1010;

  .alert {
    left: -50%;
  }
}

.gtfo {
  position: absolute;
  left: -99999px;
  opacity: 0;
}

.rotate-90 {
   transform: rotate(90deg);
}

.switch {
  input[type='checkbox'] {
    position: absolute;
    opacity: 0;
    left: -9999px;

    + label {
      cursor: pointer;
      position: relative;
      color: $switch-bg-off;
      margin-bottom: 0;
      transition: color 0.2s ease;

      > span {
        margin-left: 5px;
      }

      &::before {
        content: '';
        display: inline-block;
        width: $switch-width;
        height: $switch-height;
        background-color: $switch-bg-off;
        border-radius: 10px;
        z-index: 1;
        margin-right: 5px;
        transition: background-color 0.2s ease;
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: calc((2 * #{$switch-padding}) - (#{$switch-padding / 2}));
        width: $switch-inner-radius;
        height: $switch-inner-radius;
        left: $switch-offset-off;
        border-radius: 50%;
        background-color: $switch-inner-color;
        z-index: 2;
        transition: left 0.2s ease;
      }
    }

    &:checked {
      + label {
        color: $switch-bg-on;

        &::before {
          background: $switch-bg-on;
        }

        &::after {
          left: $switch-offset-on;
        }
      }
    }
  }
}

.mk-checkbox {
   position: relative;
   width: $checkbox-width;
   height: $checkbox-height;
   border-radius: $checkbox-border-radius;
   border: $checkbox-border-width solid $checkbox-border-color;
   margin: 0;

   &-value {
      position: absolute;
      top: -$checkbox-border-width;
      margin: 0;
      padding: 0;
      border-radius: $checkbox-border-radius;
      transform-origin: center;
      color: $blue;
      transform: rotateY(90deg);
      transition: all 0.1s ease;
   }

   input[type="checkbox"] {
      &:checked {
         + .mk-checkbox-value {
            transform: rotateY(0);
         }
      }
   }
}

.mk-radio {
   position: relative;

   &-label {
      border: $radio-border-width solid $radio-border-color;
      color: $radio-label-color;
      padding: $radio-padding;
      border-radius: $radio-border-radius;
      cursor: pointer;
   }

   &:hover {
      .mk-radio-label {
         background-color: $radio-border-color;
         color: white;
      }
   }

   > input {
      position: absolute;
      left: -99999px;
      opacity: 0;

      &:checked {
         + .mk-radio-label {
            background-color: $radio-label-color;
            color: white;
         }
      }
   }
}

.dropdown {
   .dropdown-toggle-custom {
      cursor: pointer;
      color: $blue;

      &:hover {
         color: darken($blue, 20);
      }
   }
}

.clearable-input {
   position: relative;

   > input[type="text"][allow-clear="1"] {
      + i {
         display: none;
         position: absolute;
         top: 0.4em;
         right: 0.2em;
         color: $grey;
         font-size: 1em;
         cursor: pointer;
      }
   }
}
